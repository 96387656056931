const initialState = {
  approvsLoading: false,
  approvsError: null,
  approvs: [],
  approvsReload: false,
  approvLoading: false,
  approvError: null,
  approv: null,
  approvReload: false,
  approvSelect: [],
  approvSelectLoading: false,
  currentComments: [],
  currentCommentsLoading: false,
  commentsLoading: false,
  approvsCount: null,
  approvsRefetchLoading: false,
  packing: null,
  packingLoading: false,
  packingError: null,
};

const approvReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "APPROVS_LOADING": {
      return { ...state, approvsLoading: true, approvsRefetchLoading: false };
    }

    case "APPROVS_ERROR": {
      return {
        ...state,
        approvsLoading: false,
        approvsError: payload,
        approvsRefetchLoading: false,
      };
    }
    case "APPROVS_FETCHED": {
      return {
        ...state,
        approvsLoading: false,
        approvs: payload.orders,
        approvsRefetchLoading: false,
        approvsCount: payload.totalCount,
      };
    }

    case "APPROVS_SUCCESS_FETCHED": {
      return {
        ...state,
        approvsLoading: false,
        approvs: [...state.approvs, ...payload],
        approvsRefetchLoading: false,
      };
    }
    case "APPROVS_SELECT_LOADING": {
      return { ...state, approvSelectLoading: true };
    }
    case "APPROVS_REFETCH_LOADING": {
      return { ...state, approvsRefetchLoading: true, approvsLoading: false };
    }
    case "APPROV_SELECT_FETCHED": {
      return { ...state, approvSelectLoading: false, approvSelect: payload };
    }
    case "APPROVS_RELOAD": {
      return { ...state, approvsReload: !state.approvsReload };
    }
    case "APPROV_LOADING": {
      return { ...state, approvLoading: true };
    }
    case "APPROV_ERROR": {
      return { ...state, approvLoading: false, approvError: payload };
    }
    case "APPROV_FETCHED": {
      return { ...state, approvLoading: false, approv: payload };
    }
    case "APPROV_RELOAD": {
      return { ...state, approvReload: !state.approvReload };
    }
    case "APPROV_CREATED": {
      return {
        ...state,
        approvLoading: false,
        approvs: [payload, ...state.approvs],
      };
    }
    case "APPROV_UPDATED": {
      return {
        ...state,
        approvsLoading: false,
        approvLoading: false,
        approvs: state.approvs.map((el) =>
          el.id === payload.id ? { ...el, ...payload } : el
        ),
      };
    }
    case "APPROV_DELETED": {
      return {
        ...state,
        approvsLoading: false,
        approvLoading: false,
        approvs: state.approvs.filter((el) => el.id !== payload),
      };
    }

    case "CREATE_COMMENTS_CONTAINER_LOADING":
      return { ...state, currentCommentsLoading: true };
    case "CREATE_COMMENTS_CONTAINER_SUCCESS":
      return {
        ...state,
        currentCommentsLoading: false,
        currentComments: [payload, ...state.currentComments],
      };

    case "FETCH_COMMENTS_CONTAINER_LOADING":
      return { ...state, commentsLoading: true };

    case "FETCH_COMMENTS_CONTAINER_SUCCESS":
      return { ...state, currentComments: payload, commentsLoading: false };

    case "CLEAR_CURR_APPROV":
      return {
        ...state,
        approv: null,
        currentComments: [],
        commentsLoading: false,
      };
    case "RESET_APPROVS":
      return {
        ...state,
        approvs: [],
        approvsCount: null,
        approvsLoading: false,
      };

    case "CREATE_SERV_PACKING_LOAD":
      return {
        ...state,
        packingLoading: true,
      };
    case "CREATE_SERV_PACKING_SUCC":
      return {
        ...state,
        packingLoading: false,
      };
    case "CREATE_SERV_PACKING_ERROR":
      return {
        ...state,
        packingLoading: false,
        packingError: payload,
      };

    case "UPDATE_CONTAINER_COMMENT_SUCCESS": {
      const updatedComments = state.currentComments.map((comment) => {
        if (+comment?.id === +payload?.id) {
          return payload;
        }
        return comment;
      });
      return {
        ...state,
        currentComments: updatedComments,
        commentsLoading: false,
      };
    }
    case "DELETE_CONTAINER_COMMENT_SUCCESS": {
      const updatedComments = state.currentComments.filter((comment) => {
        return +comment?.id !== +payload;
      });
      return {
        ...state,
        currentComments: updatedComments,
        commentsLoading: false,
      };
    }

    default:
      return state;
  }
};

export default approvReducer;
