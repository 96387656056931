import qs from "qs";
import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";
import { deleteRecord } from "../../Utils/api";
import { uploadFile } from "../../Utils";

export const editComment =
  (
    id,
    { text, attachments = [] },
    oldAttachments = [],
    type,
    successCallback,
    callback,
  ) =>
  async (dispatch) => {
    const deletedAttachments = oldAttachments?.filter((oldAttachment) => {
      return !attachments?.find(
        (attachment) => attachment?.id === oldAttachment?.id,
      );
    });

    try {
      const query = qs.stringify({
        populate: ["attachments.file", "user.photo"],
      });

      for (const attachment of deletedAttachments) {
        await deleteRecord(attachment?.id, "attachments");
        await deleteRecord(
          attachment?.attributes?.file?.data?.id,
          "upload/files",
        );
      }

      const newAttachments = attachments?.filter(
        (attachment) => attachment instanceof File,
      );

      for (const file of newAttachments) {
        const fileId = await uploadFile(file);
        await API.post(`/api/attachments?populate=*`, {
          data: {
            date: new Date(),
            file: fileId,
            comment: id,
          },
        });
      }

      const { data } = await API.put(
        `${API_END_POINT}api/comments/${id}?${query}`,
        {
          data: {
            text,
          },
        },
      );

      dispatch({ type, payload: data?.data });
      successCallback();
    } catch (error) {
      console.log(error);
    } finally {
      callback();
    }
  };

export const deleteComment =
  (id, { attachments }, type) =>
  async (dispatch) => {
    try {
      for (const attachment of attachments) {
        await deleteRecord(attachment?.id, "attachments");
        await deleteRecord(
          attachment?.attributes?.file?.data?.id,
          "upload/files",
        );
      }
      await deleteRecord(id, "comments");
      dispatch({ type, payload: id });
      // successCallback();
    } catch (error) {
      console.log(error);
    } finally {
      // callback();
    }
  };
