import React, { useEffect, useState } from "react";
import "./Cart.css";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_END_POINT } from "../../Api/EndPoints";
import SwitchButton from "../Common/FormElements/SwitchButton/SwitchButton";
import CartPackingItem from "./CartPackingItem";
import adjustPrice from "../../Utils/AdjustPrice";
import Button from "../Common/FormElements/Button/Button";
import { updateCart, validateCommande } from "../../REDUX/actions/catalogue";
import { productTypesModel } from "./utils";
import CheckBox from "../Common/FormElements/CheckBox/CheckBox";
import { Dialog } from "primereact/dialog";
import { ValideSVG } from "../Svgs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "../Common/FormElements/Select/Select";
import TextInput from "../Common/FormElements/TextInput/TextInput";
const Cart = ({
  step = 1,
  setStep,
  activeTab,
  searchInput,
  productsactiveTab,
  hideCTA = false,
}) => {
  const { t, i18n } = useTranslation("common");
  const navigate = useNavigate();

  const { userRole } = useSelector((state) => state.userReducer);
  const { cart, cartLoading } = useSelector((state) => state.catalogueReducer);
  const dispatch = useDispatch();
  const [isIncomplete, setisIncomplete] = useState(true);
  const [conditionsAgreement, setConditionsAgreement] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [currency, setcurrency] = useState(cart?.currency);
  const [exchangeRate, setexchangeRate] = useState(cart?.exchangeRate);

  // const [newTotalsMajorAccountOrder, setnewTotalsMajorAccountOrder] = useState({
  //   totalDeliveryHT: cart?.totalDeliveryHT,
  //   totalHT: cart?.totalHT,
  //   totalTTC: cart?.totalTTC,
  //   TVA: cart?.TVA,
  //   facture: null,
  //   bon_de_commande: null,
  // });

  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate("/commandes");
    localStorage.removeItem("selectedCart");
  };

  const handleChangeMajorAccount = (e) => {
    dispatch(
      updateCart(cart?.id, {
        [e.target.name]:
          e.target.name === "currency" ? e.target.value.code : e.target.value,
      })
    );
  };

  useEffect(() => {
    setisIncomplete(
      cart?.particulier
        ? !cart?.addresseDeLivraison ||
            !cart.addresseDeFacturation ||
            !cart.deliveryOptionName ||
            !cart.particulierName
        : cart?.majorAccountOrder
        ? !cart?.addresseDeLivraison ||
          !cart.addresseDeFacturation ||
          !cart.deliveryOptionName ||
          !cart.majorAccountName
        : !cart?.addresseDeLivraison ||
          !cart.addresseDeFacturation ||
          !cart.deliveryOptionName ||
          !cart.reseller
    );
  }, [cart]);

  useEffect(() => {
    cart && setcurrency(cart?.currency);
    cart && setexchangeRate(cart?.exchangeRate);
  }, [cart]);

  const handleChangeExchangeRate = (event) => {
    let inputValue = event.target.value;

    // Remove any characters that are not digits or the decimal point
    inputValue = inputValue.replace(/[^0-9.]/g, "");

    // Ensure that there is only one decimal point
    if (inputValue.includes(".") && inputValue.split(".").length > 2) {
      inputValue = inputValue.substring(0, inputValue.lastIndexOf("."));
    }

    // Ensure the input has no more than three decimal places
    if (inputValue.includes(".") && inputValue.split(".")[1].length > 6) {
      inputValue = parseFloat(inputValue).toFixed(6); // Round to 3 decimals
    }

    setexchangeRate(inputValue);
  };

  return (
    <div className="CartContainer p-3 d-flex flex-column justify-content-between">
      <Dialog
        visible={showDialog}
        style={{ width: "40vw" }}
        onHide={handleCloseDialog}
        onMaskClick={handleCloseDialog}
      >
        <div className="d-flex flex-column align-items-center">
          <ValideSVG width={84} height={84} />
          <p className="f23 sBold text-customBlack mt-3">
            {t("Thank you for your order")}
          </p>
          <p className="f18 text-brightGrey w60 text-center">
            {t("Your order has been successfully placed")}
          </p>
        </div>
      </Dialog>
      <div>
        <h2 className="quickSand text-blue bold f22">{t("Cart")}</h2>

        <div className="f14 sBold text-softBlue mt-3">
          {t("Product list")} ({cart?.totalNumberOfPackings})
        </div>
        {userRole === "admin" && cart?.id ? (
          <div>
            <div className="alignH gap-2">
              <div>{t("Major account order")}</div>
              <div>
                <SwitchButton
                  small
                  isActive={cart?.majorAccountOrder || false}
                  onClick={() => {
                    dispatch(
                      updateCart(cart?.id, {
                        majorAccountOrder: !cart?.majorAccountOrder,
                      })
                    );
                    // console.log({ cart });
                  }}
                />
              </div>{" "}
            </div>

            {!cart?.majorAccountOrder ? (
              <>
                <div className="alignH gap-2">
                  <div>{t("Order at 0 (AS)")}</div>
                  <div>
                    <SwitchButton
                      small
                      isActive={cart?.free || false}
                      onClick={() =>
                        dispatch(
                          updateCart(cart?.id, {
                            free: !cart?.free,
                            totalDeliveryHT: 0,
                            totalHT: 0,
                            totalTTC: 0,
                            TVA: 0,
                            facture: null,
                            bon_de_commande: null,
                          })
                        )
                      }
                    />
                  </div>{" "}
                </div>
                <div className="alignH gap-2">
                  <div>{t("Individual order")}</div>
                  <div>
                    <SwitchButton
                      small
                      isActive={cart?.particulier || false}
                      onClick={() =>
                        dispatch(
                          updateCart(cart?.id, {
                            particulier: !cart?.particulier,
                            reseller: null,
                            business_affiliate: null,
                            addresseDeFacturation: null,
                            addresseDeLivraison: null,
                            particulierName: null,
                            particulierEmail: null,
                            particulierPhone: null,
                            particulierCompany: null,
                            facture: null,
                            bon_de_commande: null,
                          })
                        )
                      }
                    />
                  </div>{" "}
                </div>

                {/* <div className="alignH gap-2">
                  <div>{t("Credit")}</div>
                  <div>
                    <SwitchButton
                      small
                      isActive={cart?.credit || false}
                      onClick={() =>
                        dispatch(
                          updateCart(cart?.id, {
                            credit: !cart?.credit,
                            facture: null,
                            bon_de_commande: null,
                          })
                        )
                      }
                    />
                  </div>{" "}
                </div> */}
              </>
            ) : (
              <div className="alignH gap-2 align-items-center">
                <div className="w-100">
                  <Select
                    name={"currency"}
                    placeholder={t(`${"Choose Currency" ?? ""}`)}
                    value={{ name: cart?.currency, code: cart?.currency }}
                    options={[
                      { name: "Euro", code: "Euro" },
                      { name: "Dollar", code: "Dollar" },
                    ]}
                    callback={handleChangeMajorAccount}
                    className="mt-2 f12"
                  />
                  {cart?.currency === "Dollar" && (
                    <div className="d-flex align-items-center">
                      <TextInput
                        type="text"
                        name={"exchangeRate"}
                        placeholder={t(`Exchange rate of the day`)}
                        value={exchangeRate ?? ""}
                        callback={
                          handleChangeExchangeRate

                          // (e) => setexchangeRate(e.target.value)
                        }
                        className={"my-3 w-100"}
                        surfixText="$/€"
                        // min="0"
                        // step="0.001"
                        // pattern="[A-Za-z]{3}"
                        // pattern="^\d+(\.\d{0,3})?$"
                      />
                      <Button
                        disabled={exchangeRate === cart.exchangeRate}
                        onClick={() =>
                          dispatch(
                            updateCart(cart?.id, {
                              exchangeRate: !exchangeRate
                                ? null
                                : +exchangeRate,
                            })
                          )
                        }
                      >
                        {t("Apply")}
                      </Button>
                    </div>
                  )}
                </div>{" "}
              </div>
            )}
          </div>
        ) : null}
      </div>
      {cart?.groupedPackings?.length ? (
        <>
          <div className="cartElementList d-flex flex-column my-3">
            {cart.groupedPackings.map((packingGroup, index) => {
              return (
                <div key={index} className="my-3 py-3 border-bottom">
                  <div className="d-flex flex-column f14 bold gap-3 mb-3">
                    <img
                      width={"50px"}
                      src={`${IMAGE_END_POINT}${packingGroup.mainImageURL}`}
                      alt=""
                    />
                    <div className="w100">
                      <div className="pb-3">
                        <div>
                          {i18n.language === "en"
                            ? packingGroup.productName_en ??
                              packingGroup.productName
                            : packingGroup.productName}{" "}
                          ({packingGroup.packings.length})
                        </div>
                        <div className="text-softBlue f12">
                          {t(`${productTypesModel[packingGroup.productType]}`)}
                        </div>
                      </div>
                      <CartPackingItem
                        packingGroup={packingGroup}
                        activeTab={activeTab}
                        searchInput={searchInput}
                        productsactiveTab={productsactiveTab}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div></div>
      )}
      <div className="bold">
        <div className="text-start">
          <div className="alignH justify-content-between">
            <div>{t("Delivery charges excl. tax")}</div>
            <div>
              {cart?.free
                ? 0
                : adjustPrice(
                    (
                      (cart?.majorAccountOrder &&
                      cart?.currency === "Dollar" &&
                      cart?.exchangeRate
                        ? cart?.totalDeliveryHT / cart?.exchangeRate
                        : cart?.totalDeliveryHT) || 0
                    )
                      .toFixed(2)
                      .toString()
                  )}{" "}
              {cart?.currency === "Dollar" ? "$" : "€"}
            </div>
          </div>
          <div className="alignH justify-content-between">
            <div>{t("Total product price excl. tax")}</div>
            <div>
              {cart?.free
                ? 0
                : adjustPrice(
                    (
                      (cart?.majorAccountOrder &&
                      cart?.currency === "Dollar" &&
                      cart?.exchangeRate
                        ? cart?.totalProductsHT / cart?.exchangeRate
                        : cart?.totalProductsHT) || 0
                    )
                      .toFixed(2)
                      .toString()
                  )}{" "}
              {cart?.currency === "Dollar" ? "$" : "€"}
            </div>
          </div>
          <div className="alignH justify-content-between">
            <div>{t("Total excl. tax")}</div>
            <div>
              {cart?.free
                ? 0
                : adjustPrice(
                    (cart?.majorAccountOrder &&
                    cart?.currency === "Dollar" &&
                    cart?.exchangeRate
                      ? cart?.totalHT / cart?.exchangeRate
                      : cart?.totalHT
                    )
                      ?.toFixed(2)
                      .toString()
                  )}{" "}
              {cart?.currency === "Dollar" ? "$" : "€"}
            </div>
          </div>
          <div className="alignH justify-content-between">
            <div>
              {t("VAT")}{" "}
              {cart?.free
                ? 0
                : cart?.addresseDeFacturation?.country?.name === "France"
                ? 20
                : 0}{" "}
              %
            </div>
            <div>
              {cart?.free
                ? 0
                : cart?.addresseDeFacturation?.country?.name === "France"
                ? adjustPrice(
                    (cart?.majorAccountOrder &&
                    cart?.currency === "Dollar" &&
                    cart?.exchangeRate
                      ? cart?.TVA / cart?.exchangeRate
                      : cart?.TVA
                    )
                      .toFixed(2)
                      .toString()
                  )
                : 0}{" "}
              {cart?.currency === "Dollar" ? "$" : "€"}
            </div>
          </div>
          <div className="alignH justify-content-between">
            <div>{t("Total incl. tax")}</div>
            <div>
              {cart?.free
                ? 0
                : adjustPrice(
                    (cart?.majorAccountOrder &&
                    cart?.currency === "Dollar" &&
                    cart?.exchangeRate
                      ? cart?.totalTTC / cart?.exchangeRate
                      : cart?.totalTTC
                    )
                      ?.toFixed(2)
                      .toString()
                  )}{" "}
              {cart?.currency === "Dollar" ? "$" : "€"}
            </div>
          </div>
        </div>
        {!hideCTA && (
          <div className="d-flex mt-2">
            <CheckBox
              checked={conditionsAgreement}
              callback={() => setConditionsAgreement(!conditionsAgreement)}
            />
            <p className="f14 mb-0">
              {t("I accept the terms and conditions")}{" "}
              <span className="text-blue">{t("of sales")}</span>
            </p>
          </div>
        )}
        {!hideCTA && (
          <div className="d-flex gap-2">
            {step !== 1 && (
              <Button
                className="w100 my-3"
                onClick={() => {
                  setStep(step - 1);
                }}
              >
                {t("Previous")}
              </Button>
            )}
            {step !== 4 ? (
              <Button
                disabled={!cart?.id}
                className="w100 my-3"
                onClick={() => {
                  setStep(step + 1);
                }}
              >
                {t("Next")}
              </Button>
            ) : (
              <Button
                className="w100 my-3"
                disabled={isIncomplete || !conditionsAgreement}
                loading={cartLoading}
                onClick={() => {
                  dispatch(validateCommande(setShowDialog, cart));
                  localStorage.removeItem("selectedCart");
                }}
              >
                {t("Validate the order")}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
