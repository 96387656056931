import React, { useState } from "react";
import { CloseSvg } from "../../../Svgs";
import { useTranslation } from "react-i18next";
import { IMAGE_END_POINT } from "../../../../Api/EndPoints";

const EditMessageMediaPreview = ({ attachments, setAttachments }) => {
  const { t } = useTranslation("common");
  const isFile = (file) => file instanceof File;

  const getFile = (file) => {
    if (isFile(file)) {
      return {
        url: URL.createObjectURL(file),
        name: file?.fileName ?? "",
        isImage: file?.type?.includes("image"),
        isVideo: file?.type?.includes("video"),
      };
    } else {
      return {
        url: IMAGE_END_POINT + file?.attributes?.file?.data?.attributes?.url,
        name: file?.attributes?.file?.data?.attributes?.name,
        isImage:
          file?.attributes?.file?.data?.attributes?.mime?.includes("image"),
        isVideo:
          file?.attributes?.file?.data?.attributes?.mime?.includes("video"),
      };
    }
  };
  return (
    <div className="w-100 position-absolute bottom-100 start-0 pb-2 pt-3">
      <div className="d-flex gap-2 overflow-auto no-scroll pt-3">
        {attachments?.map((file, index) => {
          const { url, name, isImage, isVideo } = getFile(file);
          return (
            <div key={file?.id ?? name} className="position-relative">
              <button
                className="message-media-close position-absolute border-0 d-flex align-items-center justify-content-center bg-white p-2"
                onClick={() =>
                  setAttachments(attachments?.filter((_, i) => i !== index))
                }
              >
                <CloseSvg />
              </button>
              <div className="bg-lightGrey outline-none border-0 br5 overflow-hidden w-100px h-100px d-flex align-items-center justify-content-center">
                {isImage ? (
                  <img
                    src={url}
                    alt={name}
                    className="ob-contain br5"
                    width={100}
                    height={100}
                  />
                ) : isVideo ? (
                  <video width={100} height={100}>
                    <source src={url} />
                  </video>
                ) : (
                  <p className="d-flex align-items-center justify-content-center mb-0 text-blue f14">
                    {t("Document")}
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EditMessageMediaPreview;
