import React from "react";
import { CloseSvg, SearchSVG } from "../../../Svgs";

import "./TextInput.css";
import Select from "../Select/Select";

function TextInput({
  width,
  height,
  className,
  inputClassName,
  placeholderStyle,
  placeholder,
  callback,
  search,
  type = "text",
  autoComplete,
  disabled,
  withSearchBtn,
  value: currentValue,
  percentage,
  euro,
  deviseType,
  surfixText,
  customSurfixSize,
  onSearchBtnClick = () => {},
  clearSearch = () => {},
  currencyChoice,
  currencyOnChange,
  currencySelectName = "",
  currencyDefaultValue = { name: "€", code: "1" },
  name,
  searchButtonType = "button",
  defaultValue,
  stopPropagation,
  step,
  min,
  max,
  ...props
}) {
  const customClassName = className || "";
  const extensionDisabled = disabled ? "disabled" : "";
  const searchInputClassName = search
    ? "searchInput py-3 pe-3 ps-25 b-1-darkGrey"
    : withSearchBtn
    ? "p-0 b-2-softGrey ps-3"
    : "ps-3 b-2-softGrey";
  const currencyOptions = [
    { name: "€", code: "1" },
    { name: "$", code: "2" },
    { name: "£", code: "3" },
  ];
  let value = currentValue;
  if (!isNaN(value) && String(value)?.length > 0 && type === "number") {
    value = Math.round(value * 100) / 100;
  }

  return (
    <div
      className={`textInputContainer d-flex position-relative ${customClassName}`}
      style={{ width }}
      onClick={(e) => stopPropagation && e.stopPropagation()}
    >
      {search ? <SearchSVG color="black" width={20} height={20} /> : null}
      <input
        type={type}
        className={`textInput br6 normal  w100 ${
          inputClassName ? inputClassName : "f16"
        } ${searchInputClassName}`}
        placeholder={placeholder}
        onChange={callback}
        style={{
          height: height,
          "--placeholderStyle": placeholderStyle ? placeholderStyle : "f15",
        }}
        autoComplete={autoComplete}
        disabled={disabled}
        value={value}
        name={name}
        defaultValue={defaultValue}
        step={step}
        min={min}
        max={max}
        {...props}
      />
      {withSearchBtn ? (
        <>
          <button
            type={searchButtonType}
            className="searchBtn colAlignC h100 pointer"
            onClick={onSearchBtnClick}
          >
            <SearchSVG color="white" width={20} height={20} />
          </button>
          {value && (
            <button
              className="search-close position-absolute bg-transparent border-0 d-flex align-items-center justify-content-center"
              onClick={clearSearch}
            >
              <CloseSvg />
            </button>
          )}
        </>
      ) : euro ? (
        <div className={`euroIcon colAlignC ${extensionDisabled}`}>
          <span className="text-softBlue normal f16">
            € {deviseType === "HT" ? "HT" : deviseType === "TTC" ? "TTC" : ""}
          </span>
        </div>
      ) : percentage ? (
        <div className={`euroIcon colAlignC ${extensionDisabled}`}>
          <span className="text-softBlue normal f16">%</span>
        </div>
      ) : surfixText ? (
        <div className={`euroIcon colAlignC h90 ${extensionDisabled}`}>
          <span
            className={`text-softBlue normal ${
              customSurfixSize ? customSurfixSize : "f16"
            } ${extensionDisabled}`}
          >
            {surfixText}
          </span>
        </div>
      ) : currencyChoice ? (
        <div
          className={`currencyDropdownContainer colAlignC h90 ${extensionDisabled}`}
        >
          <Select
            options={currencyOptions}
            panelClassName="currencyDropdown"
            defaultValue={currencyDefaultValue}
            callback={currencyOnChange}
            name={currencySelectName}
            disabled={disabled}
          />
        </div>
      ) : null}
    </div>
  );
}

export default TextInput;
