const initialState = {
  prospectsLoading: false,
  prospectsError: null,
  prospects: null,
  prospectLoading: false,
  prospectError: null,
  prospect: null,
  prospectsReload: true,
};

const prospectsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "PROSPECTS_LOADING": {
      return { ...state, prospectsLoading: true, prospectsError: null };
    }
    case "PROSPECTS_ERROR": {
      return { ...state, prospectsLoading: false, prospectsError: payload };
    }
    case "PROSPECT_CREATED": {
      return { ...state, prospectsLoading: false };
    }
    case "PROSPECT_UPDATED": {
      return { ...state, prospectsLoading: false };
    }
    case "PROSPECT_DELETED": {
      return { ...state, prospectsLoading: false };
    }
    case "PROSPECTS_FETCHED": {
      return { ...state, prospectsLoading: false, prospects: payload };
    }
    case "PROSPECT_LOADING": {
      return { ...state, propsectLoading: true };
    }
    case "PROSPECT_ERROR": {
      return { ...state, prospectLoading: false, prospectError: payload };
    }
    case "PROSPECT_FETCHED": {
      return { ...state, prospectLoading: false, prospect: payload };
    }
    case "PROSPECTS_RELOAD": {
      return { ...state, prospectsReload: !state.prospectsReload };
    }

    case "UPDATE_PROSPECT_COMMENT_SUCCESS": {
      const updatedComments = state.currentComments.map((comment) => {
        if (+comment?.id === +payload?.id) {
          return payload;
        }
        return comment;
      });
      return {
        ...state,
        currentComments: updatedComments,
        commentsLoading: false,
      };
    }
    case "DELETE_PROSPECT_COMMENT_SUCCESS": {
      const updatedComments = state.currentComments.filter((comment) => {
        return +comment?.id !== +payload;
      });
      return {
        ...state,
        currentComments: updatedComments,
        commentsLoading: false,
      };
    }

    default:
      return state;
  }
};

export default prospectsReducer;
